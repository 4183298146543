import { ENV } from './app';

import { logger } from 'utils/logger';

// API
export const API_URL = `/api/v1`;

export const TOKEN_PREFIX = 'Token ';

export enum PATHS {
  // Auth
  LOGIN = '/accounts/login/',
  // Users
  PROFILE = '/accounts/users/profile/',
  USER = '/accounts/users/:id/',
  USERS = '/accounts/users/',
  // Trips
  TRIPS_SETTINGS = '/transport/trips_settings/',
  TRIPS = '/transport/trips/',
  // Transport
  SCOOTERS = '/transport/scooter_admin/',
  SCOOTER_BY_ID = '/transport/scooter_admin/:id/',
  CHANGE_SCOOTER_ID = '/transport/change_scooter_id/:old_id/',
  RELOAD_SCOOTER = '/transport/reload/:id/',
  UNBLOCK_SCOOTER = '/transport/unblock/:id/',
  BLOCK_SCOOTER = '/transport/block/:id/',
  SIGNAL_SCOOTER = '/transport/signal/:id/',
  UNBLOCK_BATTERY_SCOOTER = '/transport/unlock_battery/:id/',
  UPDATE_LOCATION_SCOOTER = '/transport/update_geo_data/:id/',
  STATS_SCOOTER = '/transport/scooter-stats/',
  ERRORS_SCOOTER = '/transport/scooter_error/',
  // Support
  SUPPORT_TICKETS = '/chat/tickets/',
  SUPPORT_TICKETS_BY_ID = '/chat/tickets/:id/',
  SUPPORT_MESSAGES = '/chat/messages/',
  SUPPORT_CREATE_MESSAGE = '/chat/create_message/',
  SUPPORT_READ_MESSAGES = '/chat/tickets/:id/mark_messages_as_read/',
  // Zones
  ZONES = '/dropoffs/zones_areas/',
  ZONE_BY_ID = '/dropoffs/zones_areas/:id/',
  ZONE_RULES = '/dropoffs/zones_area_rules/',
  // App Config
  REMOTE_CONFIG = '/accounts/get_mobile_app_config/',
  UPDATE_REMOTE_CONFIG = '/accounts/update_mobile_app_config/',
  //Notifications
  NOTIFICATIONS = '/notifications/scheduled_notifications/',
  CREATE_NOTIFICATION = '/notifications/create_scheduled_notifications/',
  EDIT_NOTIFICATION = '/notifications/scheduled_notifications/:id/',
  ACTIVATE_NOTIFICATION = '/notifications/scheduled_notifications/:id/activate/',
  DEACTIVATE_NOTIFICATION = '/notifications/scheduled_notifications/:id/deactivate/',
}

export const PAGINATION_LIMIT = [10, 20, 50, 100, 500, 1000];

export const DEFAULT_ERROR = 'Something went wrong';

export const DEFAULT_PAGINATION = {
  count: 0,
  page: 1,
  limit: PAGINATION_LIMIT[2],
  next: false,
  previous: false,
};

logger('API_URL::', API_URL);
logger('ENV::', ENV);
