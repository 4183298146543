import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import ScooterInfoModal from './components/ScooterInfoModal';
import styles from './styles.module.css';

import cityStore from 'stores/city';
import PageHeader from 'components/PageHeader';
import { getScooters, deleteScooter } from 'api/transport';
import Table, { TableCell, TableHeadCell, TableRow } from 'components/Table';
import { DEFAULT_PAGINATION } from 'constants/api';
import Checkbox from 'components/Checkbox';
import Action from 'components/Action';
import notify from 'utils/notifications';
import ChangeScooterIdModal from './components/ChangeScooterIdModal';

type Id = Scooter['id'];

type Modal = {
  type: 'add_scooter' | 'edit_scooter' | 'edit_scooter_id',
  scooter: Scooter | null,
}

const TransportDetails = () => {
  const [modal, setModal] = useState<Modal | null>(null);
  const [search, setSearch] = useState('');
  const [data, setData] = useState<Scooter[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedLoading, setSelectedLoading] = useState(false);
  const [selected, setSelected] = useState<Scooter['id'][]>([]);
  const [order, setOrder] = useState<ScooterListFilters['ordering']>('id');
  const [pagination, setPagination] = useState<Pagination>({
    ...DEFAULT_PAGINATION,
  });

  const { selectedCity } = cityStore;

  const filters = useMemo(() => {
    if (!selectedCity) return null;

    return {
      city_id: selectedCity,
      search,
      page: pagination.page,
      limit: pagination.limit,
      ordering: order,
    };
  }, [selectedCity, search, pagination.page, pagination.limit, order]);

  useEffect(() => {
    if (filters) {
      fetchScooters(filters);
    }
  }, [filters]);

  const fetchScooters = async (filters: ScooterListFilters) => {
    setSelected([]);
    setLoading(true);

    const { data: res, success } = await getScooters(filters);

    setLoading(false);

    if (success) {
      setPagination({
        ...pagination,
        count: res.count,
        next: !!res.next,
        previous: !!res.previous,
      });

      setData(res.results);
    } else {
      setData([]);
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    setPagination({ ...DEFAULT_PAGINATION });
    setData([]);
  };

  const handleRowClick = (id: Id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((s) => s !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleSelectAll = () => {
    if (selectedAll) {
      setSelected([]);
    } else {
      setSelected(data.map(({ id }) => id));
    }
  };

  const handleNewScooterAdded = () => {
    if (filters) {
      fetchScooters(filters);
    }
  };

  const handleScooterUpdated = (scooter: Scooter) => {
    const newData = data.map((s) => (s.id === scooter.id ? scooter : s));

    setData(newData);
  };

  const orderClick = (field: keyof Scooter) => {
    setPagination({ ...DEFAULT_PAGINATION });

    if (field === order) {
      setOrder(`-${field}`);
    } else {
      setOrder(field);
    }
  };

  const handleActionClick = (action: keyof typeof ACTIONS) => async () => {
    setSelectedLoading(true);

    const _promise = await Promise.all(
      selected.map((id) => ACTIONS[action](id)),
    );

    setSelectedLoading(false);

    const success = _promise.filter(({ success }) => success);
    const length = _promise.length;
    const successLength = success.length;
    const failedLength = length - successLength;

    if (successLength) {
      notify(`Sucess: ${successLength}/${length}.`, 'success');

      fetchScooters(filters!);
    }

    if (failedLength) {
      notify(`Failed: ${failedLength}/${length}.`);
    }
  };

  const selectedAll = !!data.length && selected.length === data.length;
  const actionsDisabled = !selected.length || selectedLoading;

  return (
    <div className={styles.container}>
      <PageHeader
        title="Scooter details"
        onSearch={handleSearch}
        searchDisabled={loading || selectedLoading}
        capitilize
        actions={
          <>
            <Action
              text="Add scooter"
              icon="plus-orange"
              disabled={selectedLoading}
              onClick={() => setModal({ type: 'add_scooter', scooter: null })}
            />

            <Action
              disabled={actionsDisabled}
              text="Delete"
              icon="trash"
              onClick={handleActionClick('delete')}
            />

            <Action
              text="Edit info"
              icon="pencil"
              disabled={selected.length !== 1 || selectedLoading}
              onClick={() =>
                setModal({ type: 'edit_scooter', scooter: data.find(({ id }) => selected.includes(id)) || null })
              }
            />
          </>
        }
      />

      <div className={styles.content}>
        <Table
          pagination={pagination}
          loading={loading}
          onPaginationChange={(data: Pagination) =>
            setPagination({ ...pagination, ...data })
          }
          header={
            <TableRow>
              <TableHeadCell onClick={handleSelectAll}>
                <Checkbox checked={selectedAll} />
              </TableHeadCell>

              {HEADER_CONFIG.map(({ title, field, center }) => {
                const isOrdering = order.includes(field)
                  ? order.includes('-')
                    ? 'asc'
                    : 'desc'
                  : false;

                return (
                  <TableHeadCell
                    key={field}
                    center={center}
                    order={isOrdering}
                    onClick={() => orderClick(field)}
                  >
                    {title}
                  </TableHeadCell>
                );
              })}
            </TableRow>
          }
        >
          {data.map(
            (scooter) => {
              const { id, qr_code, frame_number, iccid, motor_series_number } = scooter;

              return (
                <TableRow
                  key={id}
                  disabled={selectedLoading && selected.includes(id)}
                  selected={selected.includes(id)}
                  onClick={() => handleRowClick(id)}
                >
                  <TableCell>
                    <Checkbox checked={selected.includes(id)} />
                  </TableCell>

                  <TableCell className={styles.orangeColor}>
                    {id ? `SK-${id}` : id}
                  </TableCell>

                  <TableCell>{frame_number ? frame_number : '-'}</TableCell>

                  <TableCell>{iccid ? iccid : '-'}</TableCell>

                  <TableCell>{qr_code ? qr_code : '-'}</TableCell>

                  <TableCell>
                    {motor_series_number ? motor_series_number : '-'}
                  </TableCell>

                  <TableCell
                    onClick={() => { setModal({ type: 'edit_scooter_id', scooter }) }}
                    className={styles.changeScooterAction}>
                    Change Scooter ID
                  </TableCell>
                </TableRow>)
            },
          )}
        </Table>
      </div>

      {modal?.type === 'add_scooter' && (
        <ScooterInfoModal
          city_id={selectedCity}
          title="Add new scooter"
          setIsOpen={() => setModal(null)}
          onSubmit={handleNewScooterAdded}
        />
      )}

      {modal?.type === 'edit_scooter' && modal?.scooter?.id && (
        <ScooterInfoModal
          city_id={selectedCity}
          title="Edit scooter"
          setIsOpen={() => setModal(null)}
          initialValues={modal.scooter}
          onSubmit={handleScooterUpdated}
        />
      )}

      {modal?.type === 'edit_scooter_id' && modal?.scooter?.id && (
        <ChangeScooterIdModal
          city_id={selectedCity}
          setIsOpen={() => setModal(null)}
          initialValues={modal.scooter}
          onSubmit={handleScooterUpdated}
        />
      )}
    </div>
  );
};

const ACTIONS = {
  delete: (id: Id) => deleteScooter(id),
} as const;

const HEADER_CONFIG = [
  { title: 'Scooter', field: 'id', center: false },
  { title: 'Frame no.', field: 'frame_number', center: false },
  { title: 'iccid (SIM)', field: 'iccid', center: false },
  { title: 'QR code', field: 'qr_code', center: false },
  {
    title: 'Motor SN',
    field: 'motor_series_number',
    center: false,
  },
] as const;

export default observer(TransportDetails);
