import { PAGINATION_LIMIT } from 'constants/api';
import { fetch } from 'utils/api';
import { logger } from 'utils/logger';

export const getScooters = async ({
  city_id,
  search,
  page,
  ordering = 'id',
  limit = PAGINATION_LIMIT[2],
}: ScooterListFilters) => {
  logger('SCOOTERS::');

  return fetch<PaginationResponse<Scooter>>('SCOOTERS', {
    params: {
      city_id,
      page,
      search,
      ordering,
      page_size: limit,
    },
  });
};

export const addScooter = async (data: Partial<Scooter>) => {
  logger('ADD_SCOOTER::');

  return fetch<Scooter, { reason: string }>(
    'SCOOTERS',
    { method: 'POST', data },
    true,
  );
};

export const updateScooter = async (
  id: Scooter['id'],
  data: Partial<Scooter>,
) => {
  logger('UPDATE_SCOOTER::', id);

  return fetch<Scooter, { reason: string }>(
    'SCOOTER_BY_ID',
    { method: 'PATCH', data },
    true,
    { id },
  );
};

export const changeScooterID = async (
  old_id: Scooter['id'],
  new_id: Scooter['id']
) => {
  logger('CHANGE_SCOOTER_ID::', old_id, new_id);
  const data = { new_id };

  return fetch<Scooter, { reason: string }>(
    'CHANGE_SCOOTER_ID',
    { method: 'POST', data },
    true,
    { old_id },
  );
};

export const deleteScooter = async (id: Scooter['id']) => {
  logger('DELETE_SCOOTER::', id);

  return fetch<null, { reason: string }>(
    'SCOOTER_BY_ID',
    { method: 'DELETE' },
    true,
    { id },
  );
};

export const putWorkScooter = async (
  id: Scooter['id'],
  data: Partial<Scooter>,
) => {
  logger('PUT_WORK_SCOOTER::');

  return fetch('SCOOTER_BY_ID', { method: 'PATCH', data }, true, { id });
};

export const reloadScooter = async (id: Scooter['id']) => {
  logger('RELOAD_SCOOTER::');

  return fetch('RELOAD_SCOOTER', { method: 'POST' }, true, { id });
};

export const blockScooter = async (id: Scooter['id']) => {
  logger('BLOCK_SCOOTER::');

  return fetch('BLOCK_SCOOTER', { method: 'POST' }, true, { id });
};

export const unblockScooter = async (id: Scooter['id']) => {
  logger('UNBLOCK_SCOOTER::');

  return fetch('UNBLOCK_SCOOTER', { method: 'POST' }, true, { id });
};

export const signalScooter = async (id: Scooter['id']) => {
  logger('SIGNAL_SCOOTER::');

  return fetch('SIGNAL_SCOOTER', { method: 'POST' }, true, { id });
};

export const updateGeoScooter = async (id: Scooter['id']) => {
  logger('UPDATE_GEO_SCOOTER::');

  return fetch('UPDATE_LOCATION_SCOOTER', { method: 'POST' }, true, { id });
};

export const unblockBatteryScooter = async (id: Scooter['id']) => {
  logger('UNBLOCK_BATTERY_SCOOTER::');

  return fetch('UNBLOCK_BATTERY_SCOOTER', { method: 'POST' }, true, { id });
};

export const getScooterStats = async () => {
  logger('GET_SCOOTER_STATS::');

  return fetch<ScooterStatsResponse>('STATS_SCOOTER');
};

export const getScooterErrors = async (id: Scooter['id']) => {
  logger('GET_SCOOTER_ERRORS::', id);

  return fetch<PaginationResponse<ScooterError>>('ERRORS_SCOOTER', {
    params: { id },
  });
};

export const getScooterTrips = async (id: Scooter['id']) => {
  logger('GET_SCOOTER_TRIPS::', id);

  return fetch<PaginationResponse<ScooterTripsInfo>>('TRIPS', {
    params: { scooter: id },
  });
};
